import PropTypes from 'prop-types'
import React from 'react'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      navActive: false
    }
    this.navToggle = this.navToggle.bind(this)
  }

  navToggle () {
    this.setState({
      navActive: !this.state.navActive
    })
  }
  componentWillMount () {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', this.handleClick, false)
    }
  }
  componentWillUnmount () {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', this.handleClick, false)
    }
  }
  handleClick = (e) => {
    if (!this.node.contains(e.target)) {
      this.setState({
        navActive: false
      })
    }
  }

  render() {
    return (
      <nav className="c-navbar" ref={node => this.node = node}>
        <div className="container">
          <div className="row">
            <div className="c-navbar__wrapper">
              <ul className="c-navbar__social">
                {/* eslint-disable */}
                <li className="c-navbar__social-item">
                  <a href="https://www.facebook.com/CurfewSeries/" className="c-navbar__social-link">
                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <path d="m29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z"/>
                    </svg>
                    <span className="u-visually-hide">View Curfew on Facebook</span>
                  </a>
                </li>
                <li className="c-navbar__social-item">
                  <a href="https://twitter.com/CurfewSeries?lang=en" className="c-navbar__social-link">
                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <path d="m32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819v0.081c0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.05-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-6e-3 -0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z"/>
                    </svg>
                    <span className="u-visually-hide">View Curfew on Twitter</span>
                  </a>
                </li>
                <li className="c-navbar__social-item">
                  <a href="https://www.instagram.com/curfewseries/" className="c-navbar__social-link">
                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <path d="m16 2.881c4.275 0 4.781 0.019 6.462 0.094 1.563 0.069 2.406 0.331 2.969 0.55 0.744 0.288 1.281 0.638 1.837 1.194 0.563 0.563 0.906 1.094 1.2 1.838 0.219 0.563 0.481 1.412 0.55 2.969 0.075 1.688 0.094 2.194 0.094 6.463s-0.019 4.781-0.094 6.463c-0.069 1.563-0.331 2.406-0.55 2.969-0.288 0.744-0.637 1.281-1.194 1.837-0.563 0.563-1.094 0.906-1.837 1.2-0.563 0.219-1.413 0.481-2.969 0.55-1.688 0.075-2.194 0.094-6.463 0.094s-4.781-0.019-6.463-0.094c-1.563-0.069-2.406-0.331-2.969-0.55-0.744-0.288-1.281-0.637-1.838-1.194-0.563-0.563-0.906-1.094-1.2-1.837-0.219-0.563-0.481-1.413-0.55-2.969-0.075-1.688-0.094-2.194-0.094-6.463s0.019-4.781 0.094-6.463c0.069-1.563 0.331-2.406 0.55-2.969 0.288-0.744 0.638-1.281 1.194-1.838 0.563-0.563 1.094-0.906 1.838-1.2 0.563-0.219 1.412-0.481 2.969-0.55 1.681-0.075 2.188-0.094 6.463-0.094zm0-2.881c-4.344 0-4.887 0.019-6.594 0.094-1.7 0.075-2.869 0.35-3.881 0.744-1.056 0.412-1.95 0.956-2.837 1.85-0.894 0.888-1.438 1.781-1.85 2.831-0.394 1.019-0.669 2.181-0.744 3.881-0.075 1.713-0.094 2.256-0.094 6.6s0.019 4.887 0.094 6.594c0.075 1.7 0.35 2.869 0.744 3.881 0.413 1.056 0.956 1.95 1.85 2.837 0.887 0.887 1.781 1.438 2.831 1.844 1.019 0.394 2.181 0.669 3.881 0.744 1.706 0.075 2.25 0.094 6.594 0.094s4.888-0.019 6.594-0.094c1.7-0.075 2.869-0.35 3.881-0.744 1.05-0.406 1.944-0.956 2.831-1.844s1.438-1.781 1.844-2.831c0.394-1.019 0.669-2.181 0.744-3.881 0.075-1.706 0.094-2.25 0.094-6.594s-0.019-4.887-0.094-6.594c-0.075-1.7-0.35-2.869-0.744-3.881-0.394-1.063-0.938-1.956-1.831-2.844-0.887-0.887-1.781-1.438-2.831-1.844-1.019-0.394-2.181-0.669-3.881-0.744-1.712-0.081-2.256-0.1-6.6-0.1z"/>
                      <path d="m16 7.781c-4.537 0-8.219 3.681-8.219 8.219s3.681 8.219 8.219 8.219 8.219-3.681 8.219-8.219c0-4.537-3.681-8.219-8.219-8.219zm0 13.55c-2.944 0-5.331-2.387-5.331-5.331s2.387-5.331 5.331-5.331 5.331 2.387 5.331 5.331-2.387 5.331-5.331 5.331z"/>
                      <path d="m26.462 7.456c0 1.06-0.859 1.919-1.919 1.919s-1.919-0.859-1.919-1.919 0.859-1.919 1.919-1.919 1.919 0.859 1.919 1.919z"/>
                    </svg>
                    <span className="u-visually-hide">View Curfew on Instagram</span>
                  </a>
                </li>
                {/* eslint-enable */}
              </ul>
              <div className="c-navbar__brand">
                <a href="/" className="c-navbar__brand-link">
                  {/* eslint-disable */}
                  <svg viewBox="0 0 687.32 128.18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M151.7,0c1.07.06,2.13.11,3.19.18a68.63,68.63,0,0,1,7.44.59c4.08.76,6.43,3.75,6.72,7.91.27,4-.63,7.76-1.54,11.55-.42,1.72-.94,3.42-1.35,5.14-.14.59-.42.74-1,.73H157c-3.4,0-6.79,0-10.19.06h-1c.57-2,1.12-3.83,1.67-5.69v-.09c.55-2.15.06-2.83-2.18-2.85H129.32a3.78,3.78,0,0,0-4.06,2.76c-.67,1.73-1.31,3.47-1.94,5.22a.91.91,0,0,1-1,.71c-3.76,0-7.52,0-11.28.15H123c-.13.4-.21.71-.32,1-.72,1.94-1.47,3.87-2.15,5.83a1,1,0,0,1-1.15.82c-1.56-.06-3.13,0-4.69,0l-16.06.07H97.32l2-2.48c1.68-2.16,1.68-2.16.17-4.41l-.88-1.3c1.44-.32,2.71-.58,4-.9a3.31,3.31,0,0,0,.8-.54,2,2,0,0,1,.7-.4c1.21-.19,1.65-.79,1.15-1.91a1.49,1.49,0,0,1,.14-1.56,7,7,0,0,0,.61-1.1,2.3,2.3,0,0,1,1.53-1.24c1.17-.49,1.33-1.28.48-2.25,1-1.74,1.94-3.51,3-5.21.35-.56,1.06-.89,1.49-1.41A5.54,5.54,0,0,0,113,8.11a5.81,5.81,0,0,1,.47-1c.68-1,.55-1.63-.67-1.87a30.87,30.87,0,0,0-4.19-.47q-9.59-.51-19.17-1-6.66-.3-13.33-.47c-3.73-.07-7.46-.07-11.19-.07H61c3.41-.14,6.81-.32,10.22-.42C80.09,2.49,89,2.21,97.93,2c7-.13,14.07-.1,21.11-.18a10.5,10.5,0,0,0,2.15-.43,31.1,31.1,0,0,1,4.36-.95c2.85-.21,5.71-.18,8.56-.26a5,5,0,0,0,.77-.15Z"/>
                    <path d="M339.77,0a8.31,8.31,0,0,0,1,.15h42.76a1.29,1.29,0,0,1,1.49,1c1.76,4.81,3.6,9.6,5.39,14.4.41,1.1.11,1.59-1.06,1.83a5.45,5.45,0,0,1-1.07.08l-32.74.07h-1c.77,2.84,1.52,5.61,2.28,8.44h-20.4c-.44-2-.89-4-1.32-5.94q-1.86-8.57-3.71-17.14a5.49,5.49,0,0,1-.13-1.07c0-1.13.25-1.44,1.34-1.62a4,4,0,0,0,.49-.16Z"/>
                    <path d="M499.67,0a7.36,7.36,0,0,0,.94.15h12.44a2,2,0,0,1,1.58.64q13.89,13.92,27.82,27.81c1.31,1.31,2.6,2.63,4,4.08H533q-9.91,0-19.84.08a1.16,1.16,0,0,1-1.23-.76Q505,17.54,498.06,3c-.06-.13-.13-.26-.19-.4C497.1,1,497.34,0,499.25.13c.07,0,.14-.08.21-.13Z"/>
                    <path d="M540.1,0a8.31,8.31,0,0,0,1,.15h14.16a1.22,1.22,0,0,1,1.17.6q10.23,15.53,20.49,31c.13.2.24.41.43.73-.34,0-.59.07-.84.07-5.71,0-11.42,0-17.13.08a1.36,1.36,0,0,1-1.31-.69q-9.65-14.67-19.33-29.32c-.1-.15-.2-.3-.29-.46-.82-1.44-.62-1.83,1-2.06a.61.61,0,0,0,.22-.13Z"/>
                    <path d="M530.62,34.11,537,46.31q14.54,27.75,29,55.51a16.59,16.59,0,0,1,.75,2.15l-5-.23L546.63,103c-2.22-.11-4.44-.17-6.66-.33a2.2,2.2,0,0,1-1.35-.66q-13.8-16.68-27.54-33.39L483.23,34.75c-.09-.11-.15-.24-.36-.58H486c4.81-.06,9.62-.1,14.42-.19a1.72,1.72,0,0,1,1.52.72Q514,49.54,526.2,64.34a9.44,9.44,0,0,0,1,1l.28-.2a18.86,18.86,0,0,0-.76-2Q520,48.91,513.32,34.79c-.12-.24-.22-.49-.39-.86.5,0,.9-.11,1.31-.11q11.63-.14,23.26-.24c3,0,6-.08,8.93-.16a1.84,1.84,0,0,1,1.5.6q5.33,5.38,10.71,10.73,9.9,9.89,19.81,19.74a8.23,8.23,0,0,0,1,.76l.28-.25L559,33.18h4.51q6.81-.07,13.62-.19a1.11,1.11,0,0,1,1.07.61l24.9,37.87q9.61,14.61,19.2,29.22a12.73,12.73,0,0,1,1.23,2.48c.34.92-.08,1.53-1.06,1.66a16.64,16.64,0,0,1-2,.06H600a1.64,1.64,0,0,1-1.33-.53q-9.51-10-19.07-19.94L564.84,69.06,543,46.18q-5.65-5.93-11.36-11.84a3.1,3.1,0,0,0-.86-.46Z"/>
                    <path d="M687.32,112.18h-3.63c-12.15,0-24.3.07-36.45,0-7.58,0-15.16-.11-22.73-.17l-26.7-.18q-9.93-.07-19.85-.18L559,111.52l-17.85-.18L519,111.18,502.18,111l-17.4-.18-16.06-.18-17.4-.19L435,110.28,418,110.09l-16.42-.18-17-.18-16.78-.18-17.31-.18-17.36-.19L315,109l-18.49-.18-20.14-.21-16.24-.12-27.51-.18c-6.2,0-12.39-.12-18.58-.12q-12.18,0-24.33.8c-6.14.42-12.3.61-18.46.8-9.28.29-18.56.51-27.84.73q-6,.14-12,.18H120.91l105.19-5.8v-.17a14.77,14.77,0,0,0-2.2-.32c-2.34.07-4.67.28-7,.32-7.61.13-15.21.29-22.82.27-5.28,0-10.57-.22-15.85-.46a12.35,12.35,0,0,1-7.1-2.56,1.85,1.85,0,0,0-1.06-.36q-14.72-.08-29.41-.09a6.73,6.73,0,0,0-3.26.81,21.27,21.27,0,0,1-10,2.25c-9.16.14-18.33.44-27.49.46-4.83,0-9.67-.39-14.49-.67a10.91,10.91,0,0,1-4.92-1.62,2.62,2.62,0,0,0-1.25-.36c-5.8,0-11.6.16-17.4.21-8.9.06-17.8.14-26.7.11-4.09,0-8.18-.47-12.25-.35-5.73.17-11.45.69-17.17,1.06-1.56.1-3.12.21-4.67.24-.35,0-.71-.31-1.06-.47.28-.32.51-.81.85-.91a38.87,38.87,0,0,1,5.83-1.47c6.94-.89,13.88-1.77,20.85-2.37,7.18-.61,14.39-.89,21.59-1.31a2.3,2.3,0,0,0,.73-.18c-2.23-.36-4.52-.26-6.7-1.38a4,4,0,0,1,1-.41c2.56-.31,5.12-.76,7.69-.84C59,94.12,66.21,94,73.39,94c4.5,0,9,.3,13.49.53a27.73,27.73,0,0,1,3.73.55,3.61,3.61,0,0,1,1,.59l-.11.26a5.86,5.86,0,0,0,1-.33,6.3,6.3,0,0,1,5.31-.37,15,15,0,0,0,6,.33c3.41-.2,6.83-.52,10.24-.58,7.07-.11,14.14-.17,21.2-.08,8.11.1,16.22.39,24.33.57,5.62.13,11.23.2,16.85.29a2.72,2.72,0,0,0,.8-.12l-23-2v-.18h7.17c7.45,0,14.91.1,22.37.2q11.58.14,23.16.36c4.3.08,8.59.17,12.88.37s8.51.47,12.75.89c3.48.34,6.94.9,10.39,1.45a4.15,4.15,0,0,1,2.81,1.44,7.34,7.34,0,0,1-1.48.68c-2.38.46-4.75.93-7.15,1.25-2.72.37-5.46.58-8.21,1,1.35.06,2.7.13,4,.17l19.11.48c5.56.13,11.11.23,16.67.37,7.6.18,15.2.4,22.79.59s15,.38,22.45.54c5.64.12,11.29.19,16.93.31l22.26.54,17.75.36,22.08.54,24.33.54,18,.42,22.36.49,18.2.42,22.18.48,24.33.54,17.85.42,24,.54,15.78.36,21.72.49L619,110l20.91.49q11.76.28,23.52.6l19.1.54c1.22,0,2.45.07,3.67.12a7.4,7.4,0,0,1,.93.12Z"/>
                    <path d="M409.45,35.06h10q9.92-.08,19.84-.21l13.89-.18c2.31,0,4.62-.1,6.94-.11a1.27,1.27,0,0,1,.93.44q4.06,7.32,8,14.69a11,11,0,0,1,1,2.61c.32,1.36-.1,2.06-1.47,2.24a44.22,44.22,0,0,1-5.39.36H442.44c-2.33,0-2.62.47-1.61,2.59,3.1,6.54,6.2,13.06,9.27,19.61a1.37,1.37,0,0,0,1.48.94h40.78a1.55,1.55,0,0,1,1.56.87c4.09,7.17,8.22,14.32,12.33,21.48.12.19.21.41.37.73l-5.2-.24-15.1-.7-19-.9-22.71-1.1c-2.82-.13-5.64-.31-8.46-.41a1.22,1.22,0,0,1-1.3-.91q-8.43-20.51-16.91-41-4-9.81-8.07-19.63C409.76,35.91,409.66,35.63,409.45,35.06Z"/>
                    <path d="M333.32,92.84c-2.69-.13-5.19-.11-7.66-.42-5.53-.69-11-1.56-16.55-2.32a2.19,2.19,0,0,1-1.94-1.37c-3.7-7.6-7.46-15.18-11.2-22.77a18.35,18.35,0,0,0-1-1.72,4,4,0,0,0-3.43-2H281c.43,8,.86,15.9,1.31,24-1.31-.53-2.68-.29-3.69-1.38a2.23,2.23,0,0,0-1.27-.43,25.09,25.09,0,0,1-2.74-.22c-1.75-.43-3.46-1-5.2-1.52a1.17,1.17,0,0,0-.86.06c-2,1.45-4.53,1.47-6.83,2.08-1,.26-2,.42-3.22.67V82.42l-.06-33.64V42.46a3.49,3.49,0,0,0-.57-2.2,12.67,12.67,0,0,1-.82-1.65h22.4l.26,2.59h20.57c2,0,2.57-.44,3-2.41h4.3q5.68,0,11.37.06c1.59,0,3.18,0,4.77.09.25,0,.67.26.69.45.56,4,.91,8-.2,12s-3.94,6.77-7.74,8.48l-.75.35Z"/>
                    <path d="M88.67,48.31a6.28,6.28,0,0,1,3.44-3.11A8.18,8.18,0,0,0,96,42c.77-1.28.48-2-1-2.2l-1.85-.18v-.32l25.11-.33c-.14.43-.22.74-.33,1l-12.63,34a13.28,13.28,0,0,0-.6,2.16,1.46,1.46,0,0,0,.94,1.84A1.44,1.44,0,0,0,106,78a38,38,0,0,0,4.4.19h15.79a3.88,3.88,0,0,0,3.93-2.47c.88-2.19,1.58-4.46,2.31-6.71,1-3,1.94-6.11,2.89-9.18.14-.43.29-.71.85-.71h21.56a2,2,0,0,1,.35.07c0,.23-.08.45-.14.66-2,6.62-3.8,13.26-5.88,19.84-1.2,3.8-2.76,7.47-4.14,11.21A1.56,1.56,0,0,1,146.45,92c-4.3.47-8.59,1.12-12.89,1.42-4.77.33-9.56.59-14.32.47-6.66-.17-13.32-.62-20-1.12-7.52-.57-15-1.34-22.53-2l-.45-.14c.43-1.7.79-3.42,1.31-5.1A25.79,25.79,0,0,1,79,82.26c.49-1,.31-1.61-.73-2.06a1.69,1.69,0,0,1,1.55-1.08,1.86,1.86,0,0,0,1.67-2,1.78,1.78,0,0,0-.4-1c.23-.38.48-.76.68-1.15s.3-.77.44-1.1L60.52,72.59v-.41c.54,0,1.09-.12,1.63-.11,2.86,0,5.71.15,8.57.1,3.06,0,6.12-.24,9.18-.37h2.17c1.09,0,1.54-.58,1.43-1.68a2,2,0,0,1,.42-1.3c.8-1,1.72-1.93,2.55-2.91a3.08,3.08,0,0,0,.36-.86,3.94,3.94,0,0,0-.84-.19l-29,.2H52.17c1.7-.18,4.27-.37,6.81-.74,4.24-.62,8.47-1.37,12.71-2a17.76,17.76,0,0,1,2.63-.14c2,0,4,.08,6,0a14.15,14.15,0,0,0,3.22-.71,1,1,0,0,0,.63-.75c0-.26-.4-.59-.69-.72a14.8,14.8,0,0,0-1.72-.52l.4-.48H78.55c2.49-1,5.25-.67,7.61-2-10.93-1.82-21.84-2.31-32.84-3.36H88.13c1.15,0,1.28-.12,1.45-1.29A5.83,5.83,0,0,0,88.67,48.31Z"/>
                    <path d="M221,38.37h20.9v4.1q-.28,7.17-.6,14.32c-.33,7.59-.6,15.19-1,22.77-.16,2.71-.8,5.4-1.2,8.1-.07.52-.29.68-.83.65l-12.42-.58a1.76,1.76,0,0,1-.78-.34c-4.1-2.88-8.49-4-13.36-2.29-.89.31-1.57.77-1.57,1.88L203,86.66l-21.87-1q-4.55-.22-9.09-.39c-.47,0-.64-.28-1-.59a13,13,0,0,0-2.33-1.56,4.7,4.7,0,0,0-1-.57,9.38,9.38,0,0,0-1.5-.24c.18-1,.38-2.18.63-3.36q4.31-19.86,8.59-39.74c.16-.78.46-1,1.22-1,3.85,0,7.7-.08,11.55-.1h8.53c-.51,3.18-1,6.22-1.49,9.26l-3,18.58c-.46,2.87-.93,5.74-1.29,8.63-.28,2.25.5,3.12,2.81,3.15,6.4.08,12.8.14,19.2.14,2.6,0,3.93-1,4.38-3.62s.63-5.12.88-7.69c.47-4.66.89-9.32,1.34-14q.6-6.31,1.21-12.65C220.88,39.36,221,38.88,221,38.37Z"/>
                    <path d="M374.65,91.36c-2.88,1-5.59,2-8.3,3a1.62,1.62,0,0,1-.62.06c-4.62-.23-9.23-.47-13.86-.67-.57,0-.66-.3-.76-.76q-3.32-15.08-6.65-30.14-2.49-11.37-5-22.76c-.06-.29-.1-.59-.16-.94h47.28V39a24.87,24.87,0,0,0-2.58-.46c-4.34-.34-8.68-.75-13-.92-8.47-.35-16.94-.58-25.41-.8-5.59-.15-11.18-.22-16.77-.31-1.74,0-3.48,0-5.23-.14l15.33-.23q15.06-.2,30.12-.36l12.63-.18c2.61,0,5.22-.08,7.84-.14.45,0,.76,0,.94.57,2.13,6.11,4.29,12.21,6.43,18.33.47,1.35.32,1.69-1.11,1.84a46.32,46.32,0,0,1-5,.17H368.48c-2.49,0-3.1.74-2.44,3.15q4.2,15.27,8.41,30.52A9.93,9.93,0,0,1,374.65,91.36Z"/>
                    <path d="M321.88,25.89H301.54c-.26-1.86-.5-3.69-.76-5.52a6.46,6.46,0,0,0-.18-1.07,2.45,2.45,0,0,0-2.66-2.18Q289.06,17,280.17,17c-1.41,0-1.83.44-1.78,1.87.07,2,.2,4.08.31,6.12v.81H258.4c0-.21-.06-.44-.06-.68,0-7.94,0-15.87-.09-23.81,0-.88.27-1.13,1.11-1.13h42.39c9.17,0,15.36,4.63,17.7,13.51,1,3.82,1.62,7.75,2.41,11.63A3.13,3.13,0,0,1,321.88,25.89Z"/>
                    <path d="M426.58,26.79l-20-.36a1,1,0,0,1-1-.73Q400.79,14,396,2.32c-.07-.17-.14-.33-.2-.5C395.37.6,395.64.2,396.92.2h50.7a2.14,2.14,0,0,1,2.13,1.26c2.66,4.84,5.41,9.63,8.11,14.44.52.92.36,1.29-.7,1.47a15.06,15.06,0,0,1-2.51.17H425c-2.18,0-2.59.63-1.65,2.63C424.41,22.35,425.48,24.52,426.58,26.79Z"/>
                    <path d="M454.87.3h17.6a2.24,2.24,0,0,1,2,.91Q487.07,16.65,499.72,32c.19.22.35.46.61.8H482.65a1.75,1.75,0,0,1-1.51-.71L458,4.18C457,2.92,456,1.7,454.87.3Z"/>
                    <path d="M242.55,25.94H222.21c.09-1.14.16-2.27.27-3.39.66-6.76,1.26-13.51,2-20.26.26-2.35-.35-2,2.15-2h16.91C243.21,8.91,242.88,17.39,242.55,25.94Z"/>
                    <path d="M202.9.44c-1.38,8.52-2.75,17-4.14,25.53H178.37c1.86-8.52,3.72-17,5.58-25.53Z"/>
                    <path d="M253.8,126.18l-3.28.44v.08l40.58,1.21v.27h-2.25c-9.48,0-19,0-28.42-.05-6.56,0-13.11-.12-19.67-.18l-17.22-.18-25.61-.24q-7.91-.07-15.79-.18l-22.36-.3-21.27-.36c-6.92-.13-13.83-.26-20.74-.43-6.45-.15-12.91-.35-19.37-.54a14.18,14.18,0,0,1-2.83-.31c.4,0,.8-.1,1.2-.12,6.21-.26,12.43-.5,18.64-.78,8.64-.39,17.28-.88,25.93-1.17,4.26-.14,8.53,0,12.8.14,7.69.18,15.38.4,23.07.6,7.12.19,14.24.41,21.36.53,8.08.14,16.17.21,24.25.26,4.66,0,9.32-.16,14,0,5.25.14,10.49.51,15.74.8a11,11,0,0,1,1.28.22Z"/>
                    <path d="m197.49 33.76h-20.8c0.52-2.4 1-4.76 1.54-7.1h20.42c-0.39 2.39-0.76 4.69-1.16 7.1z"/>
                    <path d="M258.37,27h20.42c.12,2.18.23,4.31.36,6.54H248.44v-.09c.87-.11,1.75-.24,2.62-.33a21.26,21.26,0,0,0,2.87-.26,24.47,24.47,0,0,0,3.72-1.21,1.3,1.3,0,0,0,.75-1.35C258.32,29.18,258.37,28.12,258.37,27Z"/>
                    <path d="M242.32,26.83V33.6H221.45c.22-2.26.43-4.5.64-6.77Z"/>
                    <path d="m323.24 33.38h-20.75c-0.27-2.08-0.53-4.13-0.81-6.3h19.32c0.84 0 1.28 0.1 1.39 1.09 0.16 1.73 0.53 3.41 0.85 5.21z"/>
                    <path d="M165.8,26.59c-.4,1.65-.73,3.24-1.2,4.78a1.74,1.74,0,0,1-1.75,1.32H144.18a1.79,1.79,0,0,1-.49-.06c.26-.88.51-1.73.78-2.57s.64-1.94.9-2.92a.82.82,0,0,1,.91-.72h0l19,.09A2.61,2.61,0,0,0,165.8,26.59Z"/>
                    <path d="m336.69 27.27h20.51l1.62 6h-20.82c-0.42-1.98-0.86-3.96-1.31-6z"/>
                    <path d="m406.32 27.45h19.81a1.06 1.06 0 0 1 1.15 0.71c0.71 1.61 1.48 3.2 2.28 4.89h-20.07a1 1 0 0 1 -1.17 -0.77c-0.65-1.56-1.32-3.1-2-4.83z"/>
                    <path d="M26.93,90.38A6.87,6.87,0,0,1,25,89.7c-.63-.49-.44-1.6.25-2.44a1.55,1.55,0,0,1,2.13-.56l0,0a1.24,1.24,0,0,0,.66.12c.29,0,.59-.21.87-.18.56,0,1.3,0,1.61.34a1.77,1.77,0,0,1,.26,1.62A7.93,7.93,0,0,1,29,91.07C27.84,92,27.09,91.66,26.93,90.38Z"/>
                    <path d="M45.71,67.18c-.64,0-1.18-.68-1.24-1.61a2.21,2.21,0,0,1,1.8-2.14c.55,0,1.26.94,1.3,1.74A2.41,2.41,0,0,1,45.71,67.18Z"/>
                    <path d="M99.54,19.18c.6,0,.93.4.93,1.08a2,2,0,0,1-1.79,1.92,1,1,0,0,1-1.12-.86,1.13,1.13,0,0,1,0-.26A2.31,2.31,0,0,1,99.54,19.18Z"/>
                    <path d="M248.56,79.44c.05-.67.22-1.26,1.06-1.27A1.62,1.62,0,0,1,251.05,80V80c0,.66-.21,1.26-1.06,1.27a1.64,1.64,0,0,1-1.42-1.83Z"/>
                    <path d="M102.32,10.85c.55,0,.75.28.73.83,0,.74-.68,1.46-1.36,1.32a1.13,1.13,0,0,1-.71-.78,1.3,1.3,0,0,1,1.21-1.37Z"/>
                    <path d="M86.09,10.5c-.11-.18-.41-.47-.44-.79a1.28,1.28,0,0,1,1.23-1.33H87a1.11,1.11,0,0,1,.74.75C87.84,9.79,87,10.51,86.09,10.5Z"/>
                  </svg>
                  {/* eslint-enable */}
                </a>
              </div>
              <div className="c-navbar__menu-container">
                <button className = {
                  this.state.navActive
                    ? 'c-navbar__menu is-active' : 'c-navbar__menu' }
                aria-controls="js-navbar-links" type="button"
                onClick = {this.navToggle}>
                  <span className="u-visually-hide">Menu</span>
                </button>
              </div>
              <div className = {
                this.state.navActive
                  ? 'c-navbar__links is-active' : 'c-navbar__links' }>
                <ul className="c-navbar__list">
                  <li className="c-navbar__item">
                    <a href="/"
                      className="c-navbar__link">Home</a>
                  </li>
                  <li className="c-navbar__item">
                    <a href="/howtoplay"
                      className="c-navbar__link">How to Play</a>
                  </li>
                  <li className="c-navbar__item">
                    <a href="/thestory"
                      className="c-navbar__link">The Story</a>
                  </li>
                  <li className="c-navbar__item">
                    <a href="/theprize"
                      className="c-navbar__link">The Prize</a>
                  </li>
                  <li className="c-navbar__item">
                    <a href="http://skyhlp.me/EiL73G"
                      target="_blank"
                      className="c-navbar__link">
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li className="c-navbar__item">
                    <a href="https://www.facebook.com/CurfewSeries/"
                      className="c-navbar__link">Facebook</a>
                  </li>
                  <li className="c-navbar__item">
                    <a href="https://twitter.com/CurfewSeries?lang=en"
                      className="c-navbar__link">Twitter</a>
                  </li>
                  <li className="c-navbar__item">
                    <a href="https://www.instagram.com/curfewseries/"
                      className="c-navbar__link">Instagram</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
