import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import { graphql, withPrefix } from 'gatsby'
import Img from 'gatsby-image'
import { Player, ControlBar, BigPlayButton } from 'video-react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import introBg from '../images/intro-bg.jpg'
import introText from '../images/intro-text.png'
import intro2Bg from '../images/intro2-bg.jpg'
import intro2Text from '../images/intro2-text.png'

class IndexPage extends React.Component {
  state = { x: 0, y: 0 }
  componentWillMount () {
    if (typeof window !== 'undefined') {
      document.addEventListener('scroll', this.handleScroll)
    }
  }
  componentWillUnmount () {
    if (typeof window !== 'undefined') {
      document.addEventListener('scroll', this.handleScroll)
    }
  }
  handleScroll = ev => {
    this.setState({
      y: window.scrollY,
      introOffset: window.scrollY <= window.innerHeight
        ? 'matrix(1, 0, 0, 1, 0, -' + ((this.state.y * 0.5)) + ')' : 'none'
    })
  }
  render () {
    return (
      <Layout>
        <SEO
          title="Curfew Live"
        />
        <div className="c-intro"
          style={{ transform: this.state.introOffset }}>
          <div className="container">
            <div className="row">
              <div className="c-intro__container">
                <div className="c-intro__media">
                  <img src={introBg} alt="" className="c-intro__image" />
                  <img src={introText} alt="" className="c-intro__image" />
                  <AnchorLink href='#intro2' className="c-intro__arrow">
                    {/* eslint-disable */}
                    <svg viewBox="0 0 88.02 46" xmlns="http://www.w3.org/2000/svg" className="c-intro__arrow-svg">
                      <path d="M43.73,46a5,5,0,0,1-3.13-1.31l-39-36A5,5,0,0,1,8.41,1.34L44,34.18,79.6,1.34a5,5,0,1,1,6.81,7.34l-39,36A5,5,0,0,1,43.73,46Z"/>
                    </svg>
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-intro c-intro--live" id="intro2">
          <div className="container">
            <div className="row">
              <div className="c-intro__container">
                <div className="c-intro__media">
                  <img src={intro2Bg} className="c-intro__image" />
                  <img src={intro2Text}
                    className="c-intro__image" />
                  <AnchorLink href='#team' offset='52' className="c-intro__arrow">
                    {/* eslint-disable */}
                    <svg viewBox="0 0 88.02 46" xmlns="http://www.w3.org/2000/svg" className="c-intro__arrow-svg">
                      <path d="M43.73,46a5,5,0,0,1-3.13-1.31l-39-36A5,5,0,0,1,8.41,1.34L44,34.18,79.6,1.34a5,5,0,1,1,6.81,7.34l-39,36A5,5,0,0,1,43.73,46Z"/>
                    </svg>
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-grid c-grid--nopadding" id="team">
          <div className="container">
            <div className="row">
              <div className="c-grid__container">
                <div className="c-grid__items">
                  {this.props.data.allFile.edges.map(img => {
                    const imageName = img.node.name
                    if (imageName.includes('04') ||
                      imageName.includes('10') ||
                      imageName.includes('16') ||
                      imageName.includes('22')) {
                      return (
                        <div className="c-grid__item" key={img.node.id}>
                          <div className="c-grid__media">
                            <Player poster={img.node.childImageSharp.fluid.src}>
                              <source src={withPrefix(
                                '/videos/curfew_team_choice_' +
                                img.node.name.slice(-2) + '.mp4')} />
                              <ControlBar disableCompletely={true} />
                              <BigPlayButton position="center" />
                            </Player>
                          </div>
                          {/* eslint-disable */}
                          <svg viewBox="0 0 640 640" xmlns="http://www.w3.org/2000/svg" className="c-grid__video-icon">
                            <path d="m512 224l128-128v448l-128-128v96c0 35.346-28.654 64-64 64h-384c-35.346 0-64-28.654-64-64v-384c0-35.2 28.8-64 64-64h384c35.346 0 64 28.654 64 64v96zm-256 224c70.693 0 128-57.308 128-128s-57.308-128-128-128-128 57.308-128 128 57.308 128 128 128zm0-64c-35.346 0-64-28.654-64-64s28.654-64 64-64 64 28.654 64 64-28.654 64-64 64z"/>
                          </svg>
                          {/* eslint-enable */}
                        </div>
                      )
                    } else if (imageName.includes('06')) {
                      return (
                        <div className="c-grid__item" key={img.node.id}>
                          <a href={'/curfew_volvo'}>
                            <Img fluid={img.node.childImageSharp.fluid}
                              className="c-grid__media" />
                          </a>
                        </div>
                      )
                    } else if (imageName.includes('12')) {
                      return (
                        <div className="c-grid__item" key={img.node.id}>
                          <a href={'/curfew_camper'}>
                            <Img fluid={img.node.childImageSharp.fluid}
                              className="c-grid__media" />
                          </a>
                        </div>
                      )
                    } else if (imageName.includes('18')) {
                      return (
                        <div className="c-grid__item" key={img.node.id}>
                          <a href={'/curfew_jag'}>
                            <Img fluid={img.node.childImageSharp.fluid}
                              className="c-grid__media" />
                          </a>
                        </div>
                      )
                    } else if (imageName.includes('24')) {
                      return (
                        <div className="c-grid__item" key={img.node.id}>
                          <a href={'/curfew_ambulance'}>
                            <Img fluid={img.node.childImageSharp.fluid}
                              className="c-grid__media" />
                          </a>
                        </div>
                      )
                    }
                    return (
                      <div className="c-grid__item" key={img.node.id}>
                        <Img fluid={img.node.childImageSharp.fluid}
                          className="c-grid__media" />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    )
  }
}

export const query = graphql`
  query ImagesQuery {
    allFile (
      sort: {order: ASC, fields: [name]},
      filter: {extension: {regex: "/(jpg)|(png)|(mp4)/"}, 
      relativeDirectory: {eq: "curfew_team_choice"}}
    ) {
      edges {
        node {
            childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid (maxWidth: 400) {
              ...GatsbyImageSharpFluid,
              src
            }
          },
          name,
          id
        }
      }
    }
  }
`

export default IndexPage
